import { CartItem } from "@typings/cart";
import { isNil } from "lodash";
import { doseNameToOption } from "./getOptionFromDoseName";
import { subscriptionTypeToOfferId } from "./subscriptionTypeToOfferId";

export const cartItemsToOffers = (cartItems: CartItem[]) =>
  cartItems.map(({ product: { id, subscriptionType, optionId, optionValue, dose }, quantity }) => ({
    offer_id: subscriptionTypeToOfferId[subscriptionType],
    order_offer_quantity: quantity,
    item_id: id,
    order_offer_item_options:
      optionId &&
      [
        { item_option_id: optionId, item_option_value_id: optionValue },
        // @ts-expect-error: Temp solution
        dose && doseNameToOption[dose],
      ].filter((e) => !isNil(e)),
  }));

export const cartItemsToUpsellOffers = (cartItems: CartItem[], parentOfferId: number) =>
  cartItems.map(({ product: { id, subscriptionType, optionId, optionValue, dose }, quantity }) => ({
    offer_id: subscriptionTypeToOfferId[subscriptionType],
    order_offer_quantity: quantity,
    item_id: id,
    order_offer_upsell: true,
    parent_order_id: parentOfferId,
    parent_offer_id: 1,
    order_offer_item_options:
      optionId &&
      [
        { item_option_id: optionId, item_option_value_id: optionValue },
        // @ts-expect-error: Temp solution
        dose && doseNameToOption[dose],
      ].filter((e) => !isNil(e)),
  }));
