import { CartItem } from "@typings/cart";
import { Product } from "scripts/generateProductsJson";
import { transformCartItemsToGA } from "./utils/transformCartItemsToGA";

const gaAddToCart = (cartItems: CartItem[]): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "USD",
        items: transformCartItemsToGA(cartItems),
      },
    });
  }
};

const gaRemoveFromCart = (cartItems: CartItem[]): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "USD",
        items: transformCartItemsToGA(cartItems),
      },
    });
  }
};

const gaViewItem = (product: Product): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "view_item",
      ecommerce: {
        currency: "USD",
        items: transformCartItemsToGA([{ product, quantity: 1 }]),
      },
    });
  }
};

const gaViewCart = (cartItems: CartItem[]): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "view_cart",
      ecommerce: {
        currency: "USD",
        items: transformCartItemsToGA(cartItems),
      },
    });
  }
};

const gaSelectItem = (product: Product): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "select_item",
      ecommerce: {
        currency: "USD",
        items: transformCartItemsToGA([{ product, quantity: 1 }]),
      },
    });
  }
};

const gaPurchase = ({
  cartItems,
  user_data,
  ecommerce,
}: {
  cartItems: CartItem[];
  [key: string]: any;
}): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "purchase",
      ecommerce: {
        ...ecommerce,
        items: transformCartItemsToGA(cartItems),
      },
      user_data,
    });
  }
};

const gaBeginCheckout = ({ value, cartItems }: { cartItems: CartItem[]; value: any }): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "begin_checkout",
      ecommerce: {
        value,
        currency: "USD",
        items: transformCartItemsToGA(cartItems),
      },
    });
  }
};

const gaViewItemList = ({ products }: { products: Product[] }): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "view_item_list",
      ecommerce: {
        currency: "USD",
        items: transformCartItemsToGA(products.map((product) => ({ product, quantity: 1 }))),
      },
    });
  }
};

const gaAddShippingInfo = ({
  cartItems,
  user_data,
  ecommerce,
}: {
  cartItems: CartItem[];
  [key: string]: any;
}): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "add_shipping_info",
      ecommerce: {
        ...ecommerce,
        shipping_tier: "FREE",
        items: transformCartItemsToGA(cartItems),
      },
      user_data,
    });
  }
};

const gaAddPaymentInfo = ({
  cartItems,
  user_data,
  ecommerce,
}: {
  cartItems: CartItem[];
  [key: string]: any;
}): void => {
  if (window) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: "add_payment_info",
      ecommerce: {
        ...ecommerce,
        items: transformCartItemsToGA(cartItems),
      },
      user_data,
    });
  }
};

export {
  gaAddToCart,
  gaViewItem,
  gaViewCart,
  gaRemoveFromCart,
  gaSelectItem,
  gaPurchase,
  gaBeginCheckout,
  gaViewItemList,
  gaAddShippingInfo,
  gaAddPaymentInfo,
};
