export const BASE_URL = "https://officialgroove.com";

export const FormFieldsName = {
  email: "Email",
  firstName: "First Name",
  lastName: "Last Name",
  streetAddress: "Street Address",
  city: "Town / City",
  zipCode: "Zip Code",
  state: "State",
  phoneNumber: "Phone Number",
  cardNumber: "Card Number",
  cardError: "Card Number",
  cardExpire: "Expiration Date",
  expireError: "Expiration Date",
  cardCode: "Security Code",
  cvvError: "Security Code",
};
