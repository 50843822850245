import untypedProducts from "@data/products.json";

import { Product, ProductJson } from "scripts/generateProductsJson";

import { CartItem } from "@typings/cart";

const products = untypedProducts as ProductJson;

const randomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const getUpsellProducts = (cartItems: CartItem[], isCart = false) => {
  const excludedSKUs = Array.from(new Set(cartItems.map((item) => item.product.sku)));

  const filteredProducts = Object.entries(products).filter(
    ([sku, product]) =>
      product.subscriptionType === "MONTHLY" &&
      product.packageSize === 1 &&
      !excludedSKUs.includes(sku)
  );

  const edibleProducts = filteredProducts.filter(([, product]) => product.type === "EDIBLE");
  const flowerProducts = filteredProducts.filter(([, product]) => product.type === "FLOWER");

  const upsellProducts: Product[] = [];
  const categoriesInCart = Array.from(new Set(cartItems.map((item) => item.product.category)));

  if (flowerProducts.length > 0) {
    categoriesInCart.forEach((category) => {
      const matchingFlowers = flowerProducts.filter(([, product]) => product.category === category);

      if (matchingFlowers.length > 0) {
        upsellProducts.push(matchingFlowers[randomNumber(0, matchingFlowers.length - 1)][1]);
      }
    });
  }

  if (edibleProducts.length > 0) {
    upsellProducts.push(edibleProducts[randomNumber(0, edibleProducts.length - 1)][1]);
    if (isCart) {
      upsellProducts.push(edibleProducts[randomNumber(0, edibleProducts.length - 1)][1]);
    }
  }

  return upsellProducts;
};

export default getUpsellProducts;
