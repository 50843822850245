import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { addToCart, removeFromCart, updateItemsQuantities } from "@store/cart";

import type { CartItem } from "@typings/cart";

import Counter from "@components/common/Counter";
import Icon from "@components/common/Icon";

import posthog from "posthog-js";
import { CartItemType } from "../CartItem";

interface CartItemFooterProps {
  cartItem: CartItem;
  type: CartItemType;

  hideRemoveBtn?: boolean;
  hideCounter?: boolean;

  isRemoveProgressProduct?: boolean;
  isTopDeal?: boolean;
}

const CartItemFooter = ({
  cartItem,
  type,
  hideRemoveBtn,
  hideCounter,
  isRemoveProgressProduct,
  isTopDeal,
}: CartItemFooterProps) => {
  const { product, isFree } = cartItem;
  const [localQuantity, setLocalQuantity] = useState(cartItem.quantity);

  const dispatch = useDispatch();

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        cartItems: [{ ...cartItem, quantity: isTopDeal ? localQuantity : cartItem.quantity }],
      })
    );

    if (typeof window !== "undefined") {
      posthog.capture("Add to cart", {
        product_id: cartItem.product.id,
        product_name: cartItem.product.name,
      });
    }
  };

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart({ cartItems: [cartItem], isRemoveProgressProduct }));

    if (typeof window !== "undefined") {
      posthog.capture("Remove from cart", {
        product_id: product.id,
        product_name: product.name,
      });
    }
  };

  const handleChangeQuantity = (newQuantity: number) => {
    dispatch(
      updateItemsQuantities({
        cartItems: [{ ...cartItem, quantity: newQuantity }],
        isRemoveProgressProduct,
      })
    );
  };

  return type === "ADD" ? (
    <div className="cart-item__footer">
      {!isFree && !hideCounter && (
        <Counter
          className="cart-item__counter"
          count={isTopDeal ? localQuantity : cartItem.quantity}
          setCount={isTopDeal ? setLocalQuantity : handleChangeQuantity}
        />
      )}

      <button onClick={handleAddToCart} type="button" className="cart-item__add">
        Add
      </button>
    </div>
  ) : (
    <div className="cart-item__actions">
      {!isFree && !hideCounter && (
        <Counter
          className="cart-item__counter"
          count={cartItem.quantity}
          setCount={handleChangeQuantity}
        />
      )}

      {!hideRemoveBtn && !isFree && (
        <button onClick={handleRemoveFromCart} type="button" className="cart-item__remove">
          <Icon width={16} height={16} name="trash" />
          <span>Remove</span>
        </button>
      )}
    </div>
  );
};

export default CartItemFooter;
