import React, { FC } from "react";

import Icon from "@components/common/Icon";
import classNames from "classnames";

type CounterProps = {
  className?: string;
  count: number;
  setCount: (newCount: number) => void;
};

const Counter: FC<CounterProps> = ({ className, count, setCount }) => {
  const handleDecrease = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.currentTarget.value;

    if (value >= 1) {
      setCount(value);
    }
  };

  return (
    <div className={classNames("counter", className)}>
      <button type="button" onClick={handleDecrease}>
        <Icon name="minus" className="minus-icon" />
      </button>

      <input type="number" value={count} onChange={handleInputChange} min="1" />

      <button type="button" onClick={() => setCount(count + 1)}>
        <Icon name="plus" className="plus-icon" />
      </button>
    </div>
  );
};

export default Counter;
