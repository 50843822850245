import { ProductType } from "scripts/generateProductsJson";
import { RootState } from "../../index";

/* Select Creators */
export const selectIsCartOpen = (state: RootState) => state.cart.isCartOpen;
export const selectIsCartLoaded = (state: RootState) => state.cart.isCartLoaded;
export const selectCartItems = (state: RootState) => state.cart.cartItems;

export const selectTotalPrice = (state: RootState) =>
  +state.cart.cartItems
    .filter((item) => !item.isFree)
    .reduce((acc, item) => acc + item.product.price * item.quantity, 0)
    .toFixed(2);

export const selectOldTotalPrice = (state: RootState) =>
  +state.cart.cartItems
    .filter((item) => !item.isFree)
    .reduce((acc, item) => acc + (item.product.oldPrice || item.product.price) * item.quantity, 0)
    .toFixed(2);

export const selectAvailableBOGOFreeProducts = (state: RootState) => {
  const productTypes = state.cart.cartItems.reduce<ProductType[]>((acc, item) => {
    return acc.includes(item.product.type) ? acc : [...acc, item.product.type];
  }, []);

  return productTypes.reduce<ProductType[]>((acc, type) => {
    const filteredProducts = state.cart.cartItems.filter(
      (item) => item.promos?.includes("withBOGO") && item.product.type === type
    );

    const amountsOfProducts = filteredProducts.reduce(
      (amounts, item) => {
        if (item.isFree) {
          return { ...amounts, freeProductsAmount: amounts.freeProductsAmount + item.quantity };
        }

        return { ...amounts, paidProductsAmount: amounts.paidProductsAmount + item.quantity };
      },
      { freeProductsAmount: 0, paidProductsAmount: 0 }
    );

    if (amountsOfProducts.paidProductsAmount > amountsOfProducts.freeProductsAmount) {
      return [...acc, type];
    }

    return acc;
  }, []);
};
