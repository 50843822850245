import { CartItem } from "@typings/cart";

import { SubscriptionType } from "scripts/generateProductsJson";

interface GetUpsellProduct {
  (products?: CartItem[]): {
    productSlug?: string;
    packageSize: number;
    subscriptionType: SubscriptionType;
  };
}

const getUpsellProduct: GetUpsellProduct = (products) => {
  return {
    productSlug: products?.[0]?.product?.slug,
    packageSize: 2,
    subscriptionType: "MONTHLY",
  };
};

export default getUpsellProduct;
