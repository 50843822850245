import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { Information } from "scripts/generateInformationJson";
import { Product, SubscriptionType } from "scripts/generateProductsJson";

export interface ProductValues {
  quantity: number;
  subscriptionType: SubscriptionType;
  dose?: string;
}
export interface ProductContextProps {
  product: Product;
  information: Information;

  values: ProductValues;
  setValues: Dispatch<SetStateAction<ProductValues>>;

  isUpsell?: boolean;
}

const initialValue: ProductContextProps = {
  information: {} as Information,
  product: {} as Product,

  values: {
    quantity: 1,
    subscriptionType: "MONTHLY",
  },
  setValues: () => ({}),

  isUpsell: false,
};

export const ProductContext = createContext<ProductContextProps>(initialValue);

const useProduct = (): ProductContextProps => {
  return useContext(ProductContext);
};

export default useProduct;
